import { useLocation, useMatches } from '@remix-run/react'
import {
  init as sentryInit,
  browserTracingIntegration,
  replayIntegration,
  browserProfilingIntegration,
} from '@sentry/remix'
import { useEffect } from 'react'
import {getBrowserEnv} from "~/helper/browser.env.helper";

// TODO: check if sentry is enable
export function initSentry() {
  sentryInit({
    dsn: "https://ecda9178d5aa130e5a836b287ab01889@o4507029510553600.ingest.us.sentry.io/4507158896574464",
    environment: getBrowserEnv("ENV") ?? "unknown",
    beforeSend(event) {
      if (event.request?.url) {
        const url = new URL(event.request.url)
        if (
          url.protocol === 'chrome-extension:' ||
          url.protocol === 'moz-extension:'
        ) {
          // This error is from a browser extension, ignore it
          return null
        }
      }
      return event
    },
    integrations: [
      browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      // TODO: enable once we pay sentry
      // replayIntegration(),
      browserProfilingIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}